import React, { useEffect, useState } from "react";
import movies from "../../data/movies.json";
import CardImg from "../ui/CardImg";
import CustomButton from "../ui/CustomButton";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import CardMovie from "../ui/CardMovie";
import { Link } from "react-router-dom";

const Archive = () => {
  const [more, setMore] = useState(false);
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const getfilms = () => {
    setLoading(false);
    axios
      .get("https://api.cinematographie-algerie.com/api/allfilms")
      .then((Response) => {
        const data_array = Response.data.data;
        setMovies(data_array);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setErr(true);
      });
  };
  useEffect(() => {
    getfilms();
  }, []);

  const handleButton = () => {
    setMore(!more);
  };

  const [selected, setSelected] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handle = (item) => {
    setSelected(item);
    onOpen();
  };

  return (
    <div
      id="sec2"
      className="flex flex-col justify-center items-center bg-archive bg-center bg-cover py-40"
    >
      <div className="responsive flex flex-col justify-center items-center">
        <div className="w-fit flex flex-col items-center mb-14">
          <h1
            className="text-2xl md:text-5xl font-extrabold text-white mb-4 title"
            data-aos="fade-right"
            data-aos-once="true"
          >
            ارشيف سينما الجزائر
          </h1>
          <div
            className="border-t-2 border-primary-500 rounded-full w-1/3"
            data-aos="fade-left"
            data-aos-once="true"
          ></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-8">
          {movies.slice(0, !more ? 3 : movies.length).map((item) => (
            <CardMovie key={item.id} item={item} handle={() => handle(item)} />
          ))}
        </div>
        <div data-aos="fade-up" data-aos-once="true">
          <Link to={"./movies"}>
            <CustomButton
              title={`عرض المزيد`}
              css={"px-20 py-3 text-sm mt-10"}
            />
          </Link>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {err ? (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <p className="mb-4">404 خطا</p>
                <button
                  className="text-white bg-red-500 px-4 py-2 rounded-lg"
                  onClick={() => window.location.reload()}
                >
                  اعادة تحميل الصفحة
                </button>
              </div>
            ) : loading ? (
              selected && <Detail item={selected} id={selected} />
            ) : (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Archive;

const Detail = ({ item, id }) => {
  return (
    <div className="text-sm font-medium">
      <div className="border border-primary-500 rounded-lg overflow-hidden h-[200px] mb-4 mt-4 w-full">
        <img
          src={
            "https://api.cinematographie-algerie.com/imgs/films/" + item.picture
          }
          alt=""
          className={`object-cover w-full h-full`}
        />
      </div>
      <h2 className="text-2xl font-bold">{item.title && item.title}</h2>
      {item.auther && (
        <div className="flex item-center mt-6">
          <p className="ml-2">الاخراج:</p>
          <p>{item.auther}</p>
        </div>
      )}
      {item.senareo && (
        <div className="flex item-center">
          <p className="ml-2">سيناربو:</p>
          <p className="ml-2">{item.senareo}</p>
        </div>
      )}
      {item.filmer && (
        <div className="flex item-center">
          <p className="ml-2">تصوير:</p>
          <p className="ml-2">{item.filmer}</p>
        </div>
      )}
      {item.product && (
        <div className="flex item-center">
          <p className="ml-2">انتاج:</p>
          <p className="ml-2">{item.product}</p>
        </div>
      )}

      {item.date && (
        <div className="flex item-center">
          <p className="ml-2">تاربخ:</p>
          <p className="ml-2">{item.date}</p>
        </div>
      )}
      {item.duree && (
        <div className="flex item-center">
          <p className="ml-2">المدة:</p>
          <p className="ml-2">{item.duree}</p>
        </div>
      )}
      {item.size && (
        <div className="flex item-center">
          <p className="ml-2">الحجم:</p>
          <p className="ml-2">{item.size}</p>
        </div>
      )}
      {item.actors && (
        <div className="flex item-center space-x-10">
          <p className="ml-2">تمثيل:</p>
          <ul className="grid grid-cols-3 list-disc list-inside">
            {item.actors
              .replace(/[\[\]"]/g, "")
              .split(",")
              .map((a, idA) => (
                <li key={idA} className="ml-4">
                  {a}
                </li>
              ))}
          </ul>
        </div>
      )}
      <div className="flex item-center mb-6">
        <p className="mt-6">{item.desc}</p>
      </div>
    </div>
  );
};
