import React, { useEffect, useState } from "react";
// import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

const CardArtist = ({ item, handle }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className=" h-[450px] rounded-2xl border border-primary-600 px-6 py-4 relative overflow-hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`absolute left-0 top-0 bottom-0 right-0`}>
        <img
          src={
            "https://api.cinematographie-algerie.com/imgs/actors/" +
            item.picture
          }
          alt=""
          className={`h-full object-cover w-full brightness-[.3] opacity 0.5s ease ${
            isHovered ? "" : "opacity-0"
          }`}
        />
      </div>
      <div
        className={`transition-all duration-500 ${
          isHovered ? "transform translate-y-[280px]" : "transform-none"
        }`}
      >
        <h3
          className="font-semibold text-2xl mb-2 mt-20 text-white cursor-pointer"
          onClick={handle}
        >
          {item.name}
        </h3>
        <div className="border-t-2 rounded-full border-primary-500 w-[150px] mb-6"></div>
      </div>
      <p
        className={`text-sm text-white font-extralight text-justify transition-all duration-500 ${
          isHovered ? "transform opacity-0" : "transform-none opacity-100"
        }`}
      >
        {item.desc.substring(0, 300)}...
      </p>
    </div>
  );
};

export default CardArtist;
