import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../ui/CustomButton";
import CardImg from "../ui/CardImg";

import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
// core version + navigation, pagination modules:
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Spinner } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

// import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { DataArray } from "@mui/icons-material";

const News = () => {
  const [selected, setSelected] = useState(0);
  const [selectedOp, setSelectedOp] = useState(false);

  const [detailNew, setDetailNew] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const [news, setNews] = useState([]);
  const swiperRef = useRef(null);
  const getNews = () => {
    setLoading(false);
    axios
      .get("https://api.cinematographie-algerie.com/api/news")
      .then((Response) => {
        const data_array = Response.data.data;
        setNews(data_array);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setErr(true);
      });
  };
  useEffect(() => {
    getNews();
  }, []);
  const handleButtonClick = (slideIndex) => {
    setSelected(slideIndex);
    swiperRef.current.swiper.slideTo(slideIndex);
  };

  // const handle = (slideIndex) => {
  //   setSelected(slideIndex);
  //   swiperRef.current.swiper.slideTo(slideIndex);
  //   onOpen();
  // };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div
      id="sec4"
      className="flex flex-col justify-center items-center bg-News2 pt-40 pb-80 bg-news bg-cover bg-center relative"
    >
      <div className="responsive flex flex-col justify-center items-center">
        <div className="w-fit flex flex-col items-center mb-14">
          <h1
            className="text-5xl font-extrabold text-white mb-4 title"
            data-aos="fade-right"
            data-aos-once="true"
          >
            اخر المستجدات
          </h1>
          <div
            className="border-t-2 border-primary-500 rounded-full w-1/3"
            data-aos="fade-left"
            data-aos-once="true"
          ></div>
        </div>

        <div className="hidden md:block w-[90%] z-40">
          <Swiper
            ref={swiperRef}
            onSwiper={(s) => setSelected(s.activeIndex)}
            centeredSlides
            initialSlide={0}
            slidesPerView={3}
            spaceBetween={20}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="swiper-container news"
            onSlideNextTransitionStart={() =>
              selected < news.length - 1 && handleButtonClick(selected + 1)
            }
            onSlidePrevTransitionStart={() =>
              selected > 0 && handleButtonClick(selected - 1)
            }
          >
            {news.map((item, idx) => {
              return (
                <SwiperSlide key={item.id}>
                  <CardImg
                    handle={() => {
                      setDetailNew(item);
                      onOpen();
                    }}
                    item={item}
                    css={
                      selected !== null && selected === idx
                        ? "h-[400px] bg-gray-400 cursor-pointer"
                        : "bg-gray-400 mt-20 mx-auto brightness-[0.3] w-[200px] h-[280px] mb-8"
                    }
                  >
                    <img
                      src={
                        "https://api.cinematographie-algerie.com/imgs/news/" +
                        item.picture
                      }
                      alt=""
                      className={`h-full object-cover w-full`}
                    />
                  </CardImg>
                </SwiperSlide>
              );
            })}
            {/* <SwiperSlide>Movie 1</SwiperSlide>
                        <SwiperSlide>Movie 2</SwiperSlide>
                        <SwiperSlide>Movie 3</SwiperSlide> */}
            {/* Add more SwiperSlide components as needed */}
          </Swiper>
        </div>

        <div className="md:hidden w-[80%] z-40">
          <Swiper
            ref={swiperRef}
            onSwiper={(s) => setSelected(s.activeIndex)}
            centeredSlides
            slidesPerView={0}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="swiper-container"
            onSlideNextTransitionStart={() =>
              selected < news.length - 1 && handleButtonClick(selected + 1)
            }
            onSlidePrevTransitionStart={() =>
              selected > 0 && handleButtonClick(selected - 1)
            }
          >
            {news.map((item, idx) => {
              return (
                <SwiperSlide key={item.id}>
                  <CardImg
                    handle={() => {
                      setDetailNew(item);
                      onOpen();
                    }}
                    item={item}
                    css={
                      selected !== null && selected === idx
                        ? "h-[400px]"
                        : "mt-20 mx-auto brightness-[0.3] w-[200px] h-[280px] mb-8"
                    }
                  >
                    <img
                      src={
                        "https://api.cinematographie-algerie.com/imgs/news/" +
                        item.picture
                      }
                      alt=""
                      className={`h-full object-cover w-full`}
                    />
                  </CardImg>
                </SwiperSlide>
              );
            })}
            {/* <SwiperSlide>Movie 1</SwiperSlide>
                        <SwiperSlide>Movie 2</SwiperSlide>
                        <SwiperSlide>Movie 3</SwiperSlide> */}
            {/* Add more SwiperSlide components as needed */}
          </Swiper>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {err ? (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <p className="mb-4">404 خطا</p>
                <button
                  className="text-white bg-red-500 px-4 py-2 rounded-lg"
                  onClick={() => window.location.reload()}
                >
                  اعادة تحميل الصفحة
                </button>
              </div>
            ) : loading ? (
              detailNew && (
                <div>
                  <div className="border border-primary-500 rounded-lg overflow-hidden w-full h-[200px] mb-4">
                    <img
                      src={
                        "https://api.cinematographie-algerie.com/imgs/news/" +
                        detailNew.picture
                      }
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <h1 className="font-bold text-2xl mb-4">
                    {detailNew.title}{" "}
                  </h1>
                  <div className="border-t-2 rounded-full border-gray-300 w-[200px] mb-4"></div>
                  <p className="mb-4 text-justify">{detailNew.desc}</p>
                </div>
              )
            ) : (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default News;
