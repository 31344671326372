import React, { useEffect, useState } from "react";
//import movies from '../data/movies.json'
import CardMovie from "../components/ui/CardMovie";
import IcVideo from "../assets/icons/ic-video.svg";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "../components/ui/CustomButton";
import NavBar2 from "../layout/NavBar2";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";

const ArchiveSearch = () => {
  var data_array, cats_array;
  const [movies, setMovies] = useState([]);
  const [cats, setCats] = useState([]);
  const getfilms = () => {
    setLoading(false);
    axios
      .get("https://api.cinematographie-algerie.com/api/allfilms")
      .then((Response) => {
        const data_array = Response.data.data;
        setMovies(data_array);
        setCats(Response.data.cats);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setErr(true);
      });
  };

  useEffect(() => {
    getfilms();
    window.scrollTo(0, 0);
  }, []);

  const [selected, setSelected] = useState(-1);

  const [more, setMore] = useState(movies.length);
  const [cat, setCat] = useState(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const handle = (item) => {
    setSelected(item);
    onOpen();
  };

  const [selectcategory, setSelectCategory] = useState();

  function FilmList(selectedCategory) {
    setLoading(false);
    axios
      .post("https://api.cinematographie-algerie.com/api/api/filterfilms", {
        category: selectedCategory,
      })
      .then((Response) => {
        data_array = Response.data.data;
        setMovies(data_array);
        setLoading(true);
      })
      .catch((error) => {
        console.error("Error fetching films:", error);
        setErr(true);
      });
  }

  const [active, setActive] = useState("كل افلام ");

  const handleClick = (e, id) => {
    e.preventDefault();
    const title = e.currentTarget.getAttribute("title");
    const idx = e.currentTarget.getAttribute("catid");
    setActive(title);
    setCat(id);
    title == "كل افلام" ? getfilms() : FilmList(idx);
  };

  const handleChange = (e) => {
    const wordSearch = e.target.value;
    axios
      .post("https://api.cinematographie-algerie.com/api/search", {
        query: wordSearch,
      })
      .then((Response) => {
        data_array = Response.data.data;
        setMovies(data_array);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="relative bg-alger bg-cover">
      <div className="absolute top-0 left-0 bottom-0 right-0 bg-[#F4C285] bg-opacity-60"></div>

      <NavBar2 />
      <div className="responsive relative ">
        <form className="pt-32 z-30 relative w-full mb-10 mx-auto">
          <button className="bg-[#53391C] text-white rounded-lg absolute left-4 bottom-2 px-3">
            بحث
          </button>
          <input
            onChange={handleChange}
            type="text"
            className="rounded-md border-2 border-[#53391C] w-full h-[40px] bg-transparent"
          />
        </form>

        <div className="grid grid-cols-5 gap-6 relative z-30 mb-10 md:mx-auto w-full">
          <div
            onClick={(e) => setCat(-1)}
            title="كل افلام"
            className={`px-10 md:px-0 mb-2 md:mb-0 justify-center rounded-lg flex items-center ${
              cat === -1 ? "bg-[#53391C]" : "cursor-pointer bg-[#C98E4B]"
            } py-2`}
          >
            <img src={IcVideo} alt="" className="w-6 ml-2" />
            <p className="text-white font-semibold"> كل افلام </p>
          </div>
          {cats.map((item) => (
            <div
              key={item.id}
              onClick={(e) => setCat(item.id)}
              catid={item.id}
              title={item.name}
              className={`px-10 md:px-0 mb-2 md:mb-0 justify-center rounded-lg flex items-center ${
                cat === item.id ? "bg-[#53391C]" : "cursor-pointer bg-[#C98E4B]"
              } py-2`}
            >
              <img src={IcVideo} alt="" className="w-6 ml-2" />
              <p className="text-white font-semibold"> {item.name}</p>
            </div>
          ))}
        </div>

        <div>
          <h1 className="relative z-30 text-3xl font-bold text-center text-white">
            {active}
          </h1>
          <div className="border-t-2 border-primary-500 rounded-full w-[200px] mt-2 mb-10 mx-auto"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-8 pb-14">
          {cat === -1
            ? movies.map((item) => (
                <CardMovie
                  key={item.id}
                  item={item}
                  handle={() => handle(item)}
                />
              ))
            : movies
                .filter((item) => item.catId == cat)
                .map((item) => (
                  <CardMovie
                    key={item.id}
                    item={item}
                    handle={() => handle(item)}
                  />
                ))}
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {err ? (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <p className="mb-4">404 خطا</p>
                <button
                  className="text-white bg-red-500 px-4 py-2 rounded-lg"
                  onClick={() => window.location.reload()}
                >
                  اعادة تحميل الصفحة
                </button>
              </div>
            ) : loading ? (
              selected && <Detail item={selected} id={selected} />
            ) : (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ArchiveSearch;

const Detail = ({ item, id }) => {
  return (
    <div className="text-sm font-medium">
      <div className="border border-primary-500 rounded-lg overflow-hidden h-[200px] mb-4 mt-4 w-full">
        <img
          src={
            "https://api.cinematographie-algerie.com/imgs/films/" + item.picture
          }
          alt=""
          className={`object-cover w-full h-full`}
        />
      </div>
      <h2 className="text-2xl font-bold">{item.title && item.title}</h2>
      {item.auther && (
        <div className="flex item-center mt-6">
          <p className="ml-2">الاخراج:</p>
          <p>{item.auther}</p>
        </div>
      )}
      {item.senareo && (
        <div className="flex item-center">
          <p className="ml-2">سيناربو:</p>
          <p className="ml-2">{item.senareo}</p>
        </div>
      )}
      {item.filmer && (
        <div className="flex item-center">
          <p className="ml-2">تصوير:</p>
          <p className="ml-2">{item.filmer}</p>
        </div>
      )}
      {item.product && (
        <div className="flex item-center">
          <p className="ml-2">انتاج:</p>
          <p className="ml-2">{item.product}</p>
        </div>
      )}

      {item.date && (
        <div className="flex item-center">
          <p className="ml-2">تاربخ:</p>
          <p className="ml-2">{item.date}</p>
        </div>
      )}
      {item.duree && (
        <div className="flex item-center">
          <p className="ml-2">المدة:</p>
          <p className="ml-2">{item.duree}</p>
        </div>
      )}
      {item.size && (
        <div className="flex item-center">
          <p className="ml-2">الحجم:</p>
          <p className="ml-2">{item.size}</p>
        </div>
      )}
      {item.actors && (
        <div className="flex item-center space-x-10">
          <p className="ml-2">تمثيل:</p>
          <ul className="grid grid-cols-3 list-disc list-inside">
            {item.actors
              .replace(/[\[\]"]/g, "")
              .split(",")
              .map((a, idA) => (
                <li key={idA} className="ml-4">
                  {a}
                </li>
              ))}
          </ul>
        </div>
      )}
      <div className="flex item-center mb-6">
        <p className="mt-6">{item.desc}</p>
      </div>
    </div>
  );
};
