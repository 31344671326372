import React, { useEffect, useState } from "react";
import artist from "../../data/artist.json";
import CardImg from "../ui/CardImg";
import CustomButton from "../ui/CustomButton";
import CardArtist from "../ui/CardArtist";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Movie } from "@mui/icons-material";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";

const Artist = () => {
  const [more, setMore] = useState(false);
  const [actors, setActors] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const getactors = () => {
    setLoading(false);
    axios
      .get("https://api.cinematographie-algerie.com/api/allactors")
      .then((Response) => {
        const data_array = Response.data.data;
        setActors(data_array);
        // for (let data of data_array) {
        //     axios.get('https://api.cinematographie-algerie.com/api/images/actors/' + data.picture).then((Response2) => {
        //     setImgs(i => [...i, Response2.data.url])
        // }).catch((error) => console.log(error));
        // }
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setErr(true);
      });
  };
  useEffect(() => {
    getactors();
  }, []);
  const [selected, setSelected] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 2100,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  }, [more]);

  const handleButton = () => {
    setMore(!more);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handle = (id, item) => {
    setSelected(id);
    setSelectedItem(item);
    onOpen();
  };

  return (
    <div
      id="sec3"
      className="flex flex-col justify-center items-center bg-Artist2 py-40 bg-artist bg-cover bg-center relative"
    >
      <div className="absolute left-0 top-0 bottom-0 right-0 bg-black bg-opacity-25"></div>
      <div className="responsive flex flex-col justify-center items-center">
        <div className="w-fit flex flex-col items-center mb-14">
          <h1
            className="text-2xl md:text-5xl font-extrabold text-white mb-4 title"
            data-aos="fade-left"
            data-aos-once="true"
          >
            سينمائيو الجزائر
          </h1>
          <div
            className="border-t-2 border-primary-500 rounded-full w-1/3"
            data-aos="fade-right"
            data-aos-once="true"
          ></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-8">
          {actors.slice(more ? 5 : 0, more ? actors.length : 6).map((item) => (
            <div key={item.id} data-aos="zoom-in" data-aos-once="true">
              <CardArtist
                key={item.id}
                item={item}
                handle={() => handle(item.id, item)}
              />
            </div>
          ))}
        </div>
        <div data-aos="fade-up" data-aos-once="true">
          <CustomButton
            title={"1"}
            css={"px-5 py-3 text-sm mt-10 mx-2"}
            handleBtn={() => setMore(false)}
          />
          <CustomButton
            title={"2"}
            css={"px-5 py-3 text-sm mt-10 mx-2"}
            handleBtn={() => setMore(true)}
          />
          {/* <CustomButton title={'3'} css={'px-5 py-3 text-sm mt-10 mx-2'} handleBtn={()=>setMore(3)}/> */}
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {err ? (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <p className="mb-4">404 خطا</p>
                <button
                  className="text-white bg-red-500 px-4 py-2 rounded-lg"
                  onClick={() => window.location.reload()}
                >
                  اعادة تحميل الصفحة
                </button>
              </div>
            ) : loading ? (
              selected > -1 && <Detail item={selectedItem} id={selected} />
            ) : (
              <div className="h-[200px] flex flex-col justify-center items-center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </div>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Artist;

const Detail = ({ item, id }) => {
  return (
    <div className="text-sm font-medium">
      <div className="border border-primary-500 rounded-lg mb-4 mt-4 overflow-hidden">
        <img
          src={
            "https://api.cinematographie-algerie.com/imgs/actors/" +
            item.picture
          }
          alt=""
          className={`object-cover w-full h-[400px]`}
        />
      </div>
      {/* <img src={require('../../assets/img/' + imgs[selected])} alt="" className='object-cover w-full h-[400px] rounded-lg mb-4 mt-4' /> */}
      <h2 className="text-2xl font-bold">{item.name}</h2>
      <p className="mt-4">{item.desc}</p>
    </div>
  );
};
